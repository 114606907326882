import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import routes from './routes';
import { Auth } from '../boot/amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserGroup } from '../types';
import { hasPermission } from '../core';
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

async function isAuthorized() {
  return Auth.currentAuthenticatedUser()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}

async function userGroups(): Promise<UserGroup[]> {
  const cognito = (await Auth.currentAuthenticatedUser()) as CognitoUser;
  const res = cognito.getSignInUserSession();
  if (res) {
    const payload = res.getAccessToken().payload[
      'cognito:groups'
    ] as UserGroup[];
    if (payload) {
      return payload;
    }
  }
  return [];
}

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  Router.beforeEach((to, from, next) => {
    next();
  });

  Router.beforeResolve((to, from, next) => {
    to.matched.some(async (route) => {
      if (!route.meta.requiresAuth) {
        next();
      } else {
        const res = await isAuthorized();
        if (!res) {
          next({
            path: '/auth/login',
            replace: true,
          });
        } else {
          if (
            !hasPermission(await userGroups(), [
              UserGroup.Dashboard,
              UserGroup.SuperUsers,
            ])
          ) {
            next({
              path: '/auth/auth-error',
              replace: true,
              force: true,
            });
          } else {
            next();
          }
        }
      }
    });
  });

  return Router;
});
