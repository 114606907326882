import Amplify from 'aws-amplify';
import { boot } from 'quasar/wrappers';
import aws from '../aws-exports';
import { Auth } from '@aws-amplify/auth';

Auth.configure({
  aws_project_region: aws.region,
  aws_cognito_identity_pool_id: aws.identityPoolId,
  aws_cognito_region: aws.region,
  aws_user_pools_id: aws.userPoolId,
  aws_user_pools_web_client_id: aws.userPoolClientId,
  oauth: {},
  Auth: {
    identityPoolId: aws.identityPoolId,
    region: aws.region,
    userPoolId: aws.userPoolId,
    userPoolWebClientId: aws.userPoolClientId,
  },
});
//const Auth = AmplifyModules.Auth;

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(({ app } /* { app, router, ... } */) => {
  app.config.globalProperties.$amplify = Amplify;
  app.config.globalProperties.$auth = Auth;
});

export { Amplify, Auth };
