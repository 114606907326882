import { RouteRecordRaw } from 'vue-router';
import { RouterNames } from '../types';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/main-layout.vue'),
    children: [
      {
        path: '',
        name: RouterNames.DASHBOARD,
        component: () => import('pages/index.vue'),
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: '/auth',
    component: () => import('layouts/blank-layout.vue'),
    children: [
      {
        path: 'login',
        name: RouterNames.LOGIN,
        component: () => import('pages/auth/login.vue'),
      },
      {
        path: 'auth-error',
        name: RouterNames.AUTH_ERROR,
        component: () => import('pages/auth/auth-error.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
