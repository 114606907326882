
import { Auth } from './boot/amplify';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { CognitoUser } from '@aws-amplify/auth';
import { RouterNames } from './types';

export default defineComponent({
  name: 'App',
  setup() {
    const REFRESH_SESSION_TIMEOUT = 15 * 60 * 1000;
    const $router = useRouter();

    setInterval(() => {
      let run = async () => {
        try {
          const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
          if (user) {
            const currentSession = user.getSignInUserSession();
            if (currentSession) {
              user.refreshSession(currentSession.getRefreshToken(), () => '');
            } else {
              void $router.push({ name: RouterNames.LOGIN });
            }
          } else {
            void $router.push({ name: RouterNames.LOGIN });
          }
        } catch (e) {
          console.log('Unable to refresh Token', e);
          void $router.push({ name: RouterNames.LOGIN });
        }
      };
      void run();
    }, REFRESH_SESSION_TIMEOUT);

    return {};
  },
});
