import { UserGroup } from '../types';
import { includes } from 'lodash';

export function hasPermission(
  subset: UserGroup[],
  superset: UserGroup[]
): boolean {
  if (superset.length <= 0) {
    return false;
  }
  for (let i = 0; i < superset.length; i++) {
    if (includes(subset, superset[i])) {
      return true;
    }
  }
  return false;
}
